import './MobileMenu.css'

import {
    Link,
    useMatch,
    useResolvedPath,
} from "react-router-dom";

const MobileMenu = ()=>{

    const linkClickHandler = (e) => {
        document.querySelector('.menu').classList.toggle('open');
        document.querySelector('.menu-options').classList.toggle('active');
    }

    return(
        <div className="MobileMenu">
            <div className='menu-options mobile '>
                <Link onClick={linkClickHandler} to='/'>TWEET</Link>
                <Link className='button-menu-sobre' onClick={linkClickHandler} to='/sobre'>SOẞRE</Link>
                <Link className='button-menu-indique' onClick={linkClickHandler} to='/indiqueumpreto'>INDIQUE <br /> UM PRETO</Link>
            </div>
        </div>
    )
}

export default MobileMenu
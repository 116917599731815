import './MenuIconMobile.css'
import { Link } from "react-router-dom";
  

const MenuIconMobile = function () {

    const clickHandler = function (e) {
        var menu = document.querySelector('.menu');
        menu.classList.toggle('open');
        
        var menuoptions = document.querySelector('.menu-options');
        menuoptions.classList.toggle('active');
        
        if(menu.classList.value.indexOf('open') == -1){
           window.location.pathname = "/"
        }
    }

    return (
        <div>
            <Link to="/menu">
                <div
                    onClick={clickHandler}
                    className="menu mobile">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </Link>
        </div>
    )
}

export default MenuIconMobile
import TweetSection from '../components/TweetSection/TweetSection'
import './Home.css'

const Home = (props) => {

    return <div className='wrapper'>
        <TweetSection socket={props.socket} />
    </div>
}

export default Home
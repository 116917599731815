import './CookieBar.css'
import RoundedButton from '../RoundedButton/RoundedButton.jsx'
import { useRef } from 'react';

const CookieBar = () => {
    var cookieBarRef = useRef();

    const timeCookie = () => {
        try{
            cookieBarRef.current.classList.add('open')
        }catch(error){

        }

    }
    var timeOut = setTimeout(timeCookie, 1000);

    const saveLocalStorage = () => {
        localStorage.setItem('cookie', true);
        cookieBarRef.current.classList.remove('open')
    }

    if (localStorage.cookie) {
        try {
            clearTimeout(timeOut);
            cookieBarRef.current.classList.remove('open')
            cookieBarRef.current.classList.add('close')
        } catch (error) {
        }
    }

    return (
        <div className='CookieBar' ref={cookieBarRef}>
            <div className='limit'>
                <div className='cookie-container'>
                    <p className='cookieTitle'>Esse site usa cookies.</p>
                    <p className='cookieText'>
                        Usamos cookies de forma anônima para segurança e monitoramento dos dados e navegação.
                        O tweet de quem participa não é armazenado inteiramente, apenas palavras-chaves que nos
                        ajudam a correlacionar melhor o conteúdo da VozPreta com o tweet que você está digitando. 
                        Ao continuar em nosso site, ou ao clicar em 'Concordo' você concorda com o uso de cookies em nosso site e com os nossos: &nbsp;
                         <a href="/termos_de_uso_vozpreta_revistaraca.pdf" target="_blank" className='politica-link'>Termos de Uso</a> e <a href="/politica_de_privacidade_vozpreta_revistaraca.pdf" target="_blank" className='politica-link'> Política de Privacidade</a></p>

                </div>
                <div
                    onClick={saveLocalStorage}
                    className='cookie-btns'>
                    <RoundedButton
                        name='button-concordo-cookies'
                        buttonText='CONCORDO'
                        outlineColor='white'
                        background='transparent'
                        color='white'
                        width='100px'
                        fontsize='calc(var(--fontSizeDefault) * 0.9)'
                        type='tweet'
                    />
                </div>
            </div>

        </div>
    )
}

export default CookieBar
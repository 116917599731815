import occupydata from '../../data/occupys.json'
import './TweetBox.css';
import React, { useEffect, useRef, useState } from 'react'
import RoundedButton from '../RoundedButton/RoundedButton';
import skipdata from '../../data/skipwords.json'
import { textAreaAdjust, cleanSkipWords } from '../Methods/methods'

var response;
var skipwords = skipdata;
var contentdata;
var currentData;
var timer;
var websocketCheck;
var channel = 'ws';

const waitTime = 500;

const TweetBox = (props) => {
    var limit = 280;
    const [text, setText] = useState("");
    const [char, setChar] = useState(limit);
    const [content, setContent] = useState("");
    const refreshIcon = useRef();
    const textAreaRef = useRef();

    var sendData = function (tweet) {

        if (channel == 'ws') {
            console.log("channel status:" + channel);
            var data = '{"tweet":"' + tweet + '" }';
            props.socket.send(data);

        } else {
            console.log("channel status:" + channel);
            var urlWS = 'https://vozpreta.com.br/occupy/getTweets?tweet=' + tweet
            // var urlWS = 'http://localhost:8080/occupy/getTweets?tweet=' + tweet

            var xhttp = new XMLHttpRequest();
            xhttp.open("GET", urlWS, true);

            xhttp.onreadystatechange = function () {
                if (xhttp.readyState == 4 && xhttp.status == 200) {
                    // console.log(xhttp.responseText);
                    response = JSON.parse(xhttp.responseText);
                    contentdata = response.occupy;
                    if (xhttp.responseText !== currentData) {
                        refresh();
                        currentData = xhttp.responseText;
                    }
                }
            }

            xhttp.send();
        }
    }

    const concatTweet = text + "\n\n" + content;

    const getFocus = (element) => {
        element.current.focus();
    }

    const cleanSpecialChar = (string) => {
        var newString = string.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
        return newString
    }

    const fillTextArea = function (data) {
        if (data == undefined || data == '') {
            data = occupydata.occupy;
        }
        var remaining = limit - text.length;
        var tweet = false;
        var controler = 0;

        while (tweet == false && controler <= data.length) {
            var rnd = Math.floor(Math.random() * (data.length - 0)) + 0;
            if (remaining >= data[rnd].length) {
                tweet = data[rnd];
            }
            controler++;
        }
        return tweet;
    }

    const handleKeyUp = function (event) {
        setText(event.target.value)
        var cleanTweet = cleanSpecialChar(event.target.value);
        cleanTweet = cleanSkipWords(skipwords, cleanTweet);

        if ((event.code === 'Space') || event.target.value.endsWith(" ")) {
            sendData(cleanTweet);
        } else {
            clearTimeout(timer);

            timer = setTimeout(() => {
                sendData(cleanTweet);
            }, 700);
        }
    }

    const refresh = () => {
        // if (textAreaRef.value == ""){
        //     setContent("");
        //     return;
        // }
        refreshIcon.current.className += ' rotationIcon';
        try {
            setContent(fillTextArea(contentdata));
        } catch (error) {
        }
        setTimeout(() => {
            refreshIcon.current.className = 'refresh-icon';
        }, 500);
    }

    useEffect(() => {
        props.socket.onopen = function (e) {
            var data = '{"tweet": ""}';
            props.socket.send(data);
            if (contentdata == '' || contentdata == undefined) {
                websocketCheck = setTimeout(() => {
                    console.log("WebsocketStatus, channel:" + channel);
                    channel = 'https';
                }, 2000);
            }
        }

        props.socket.onmessage = function (e) {
            response = JSON.parse(e.data);
            if (response.off_words !== undefined) {
                try {
                    clearTimeout(websocketCheck);
                } catch (error) {

                }
                skipwords = response.off_words;
            } else {
                skipwords = skipwords;
            }
            contentdata = response.occupy;
            if (currentData != e.data) {
                // console.log("tweet:"+textAreaRef.current.value)
                if (textAreaRef.current.value !== "") {
                    refresh();
                }
                currentData = e.data;
            }
        }

        props.socket.onclose = function (e) {
            channel = 'https';
        }

    }, [response])

    useEffect(() => {
        setChar(limit - text.length);
    }, [text])

    window.onbeforeunload = function () {
        props.socket.close();
    };

    return (
        <div className='tweetContainer'>

            <div className='tweetHeader'>
                <img className='logo-voz desktop' src={require('../../assets/images/logo_home.png')} />
                <img className='logo-voz mobile' src={require('../../assets/images/logo_home.png')} />
                <img className='description' src="/description.svg" />
                <img className='instructions desktop' src="/instruction.svg" />
                <img className='instructions mobile instructions-mobile' src="/instruction-mobile.svg" />
            </div>


            <div
                className='tweetbox outline'
                onClick={e => {
                    getFocus(textAreaRef)
                }}
            >
                <textarea
                    ref={textAreaRef}
                    autoFocus
                    maxLength="240"
                    onChange={function (e) {
                        setContent(fillTextArea(contentdata));
                        textAreaAdjust(e.target);
                    }}
                    // onKeyPress={handleKeyDown}
                    onKeyUp={handleKeyUp}
                />

                <mark
                    className='content text'>
                    {content}
                </mark>
            </div>

            <div className='button-bar'>

                <img onClick={refresh} ref={refreshIcon} className='refresh-icon' src="/refresh-button.svg" />

                <RoundedButton
                    name='button-send-tweet'
                    buttonText='TWEET'
                    // buttonText='ENVIAR' // PARA TESTAR DADOS
                    outlineColor='white'
                    background='white'
                    color='black'
                    width='150px'
                    fontsize='calc(var(--fontSizeDefault) * 1.2)'
                    type='tweet'
                    text={text}
                    concatTweet={concatTweet}
                    occupy={content}
                    socket={props.socket}
                />

            </div>

        </div >
    )
}

export default TweetBox;
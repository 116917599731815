import './RoundedButton.css';
import React from 'react'
import { tweetar } from '../Methods/methods'

const RoundedButton = (props) => {
    var method;
    if (props.type == 'tweet') {
        method = function () { 
            
            tweetar(props.text, props.concatTweet);
            var eventName = "success_send/" + props.occupy;
            window.gtag("event", eventName, {
                value: props.occupy
            });
            props.socket.close();
            console.log(props.socket.readyState);
        };        
    }

    return (
        <div
            onClick={e => {
                try { method()} catch (error) {}
            }}
            className={'roundedButton ' + props.name}
            style={{
                border: '1px solid ' + props.outlineColor,
                backgroundColor: props.background,
                color: props.color,
                width: props.width,
                maxHeight: '50px',
                textAlign: 'center',
                padding: 'var(--defaultpadding) 0px',
                borderRadius: '50%',
                cursor: 'pointer',
                fontSize: props.fontsize
            }}
        >
            {props.buttonText}
        </div>
    )
}
export default RoundedButton;

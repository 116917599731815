import './App.css';
import Header from './components/Header/Header'
import MenuDesktop from './components/MenuDesktop/MenuDesktop'
import CookieBar from './components/CookieBar/CookieBar';
import {
  BrowserRouter as Router,
  Outlet
} from "react-router-dom";

function App() {  

  return (
    <div className="App">
      <Header />
     
      <div className='container'>
        <div className='letreiro'></div>
        <MenuDesktop />
        <Outlet />
        <div className='graph'>
        </div>
      </div>
      <CookieBar />
      <div className='footer desktop'></div>
    </div>
  );
}

export default App;

import './Header.css';
import React from 'react'
import { ReactComponent as LogoRevista } from '../../assets/images/logo-revista.svg'
import MenuIconMobile from '../MenuIconMobile/MenuIconMobile'

const Header = () => {
    return (
        <div className='header'> 
            <img 
                className='icon-voz mobile' 
                src={require('../../assets/images/icon-vozpreta.png')} 
                onClick={e=>{
                    window.location.href='/'
                }}    
            />
            <LogoRevista 
                className="logo-revista" 
                onClick={e=>{
                    window.location.href='/'
                }}    />
            <MenuIconMobile />
        </div>
    )
}
export default Header;
const textAreaAdjust = function (element) {
    element.style.height = "1px";
    element.style.height = (25 + element.scrollHeight) + "px";
}

const cleanSkipWords = (array, string) => {
    var newArray = [];
    array.forEach(word => {
       word = ' ' + word + ' ';
       newArray.push(word)  
    });
    let regex = new RegExp("\\b"+newArray.join('|')+"\\b","gi")
    var newstring = string.replace(regex, ' ');
    return newstring;
}

const tweetar = (tweet, concattweet)=>{
    const url = 'https://twitter.com/intent/tweet?text=';
    if (tweet.length == 0) {
        alert("Escreva um tweet para enviar")
    } else {
        window.open((url + encodeURIComponent(concattweet)), '_blank')
    };
}

export {textAreaAdjust, cleanSkipWords, tweetar} ;

import './Indique.css'
import React, { useEffect, useRef, useState } from 'react'

var response;

const Indique = (props) => {

    const [indique, setIndique] = useState("")

    const sendIndique = () => {
        if (indique.length <= 1) {
            alert('Você precisa indicar alguém para enviar.')
        } else {
            if (indique.charAt(0) !== '@') {
                alert('O perfil deve começar com @.')
            } else if (indique.indexOf(' ') >= 0) {
                alert('O perfil não deve conter espaços.')
            } else {
                var data = '{"indique":"' + indique + '" }';
                props.socket.send(data);
                alert('Sua indicação foi enviada com sucesso!');
                window.location.pathname = '/';
            }
        }
    }

    return (
        <div className='Indique'>
            <img className='logo-alternative-indique desktop' src={require('../assets/images/logo_conceito.png')} />
            <div className="indique-container">
                <div className='indique-texto'>
                    <img src={require('../assets/images/INDIQUE-titulo.png')} />
                    <p>

                        Divulgar e ampliar espaços para nomes e referências pretas é parte do Projeto #VozPreta.
                        <br />
                        <br />
                        Mas sabemos que existem milhares de outros nomes de pretos e pretas que você gostaria de ver por aqui.
                        <br />
                        <br />
                        Por isso, indique quem você gostaria de ver ocupando os tweets do Projeto.
                        <br />
                        <br />
                        É só mandar o “@“ do perfil que você quer indicar. 
                        Pode ser você mesmo(a) ou outra potência preta.
                        <br />
                        <br />
                    </p>
                </div>

                <form onSubmit={sendIndique} className='input-container' action="">
                    <label>Indique para gente aqui.</label>
                    <input
                        id="twitter"
                        autoFocus
                        className='input-indique'
                        type="text"
                        // pattern="^@[A-Za-z0-9_]{1,15}$"
                        required
                        onChange={e => {
                            setIndique(e.target.value)
                        }}
                    >
                    </input>

                    <button
                        type='button'
                        // type='submit'
                        onClick={sendIndique}
                        className='roundedButton button-indicar'>
                        <p>INDICAR</p>
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Indique
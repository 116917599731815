import './TweetSection.css';
import React from 'react'
import TweetBox from '../TweetBox/TweetBox'

const TweetSection = (props) => {

    return (
        <div className='tweetSection'>
            <TweetBox socket={props.socket} />
        </div>
    )
}
export default TweetSection;
import './Sobre.css'
import LinkRounded from '../components/LinkRounded/LinkRounded'

const Sobre = () => {

    return (
        <div className='Sobre'>
            <div className="sobre-container">
                <img className='logo-alternative desktop' src={require('../assets/images/logo_conceito.png')} />
                <div className='sobre-texto'>
                    <img className='titulo' src={require('../assets/images/SOBRE-titulo.png')} />
                    <p>
                        54% da população do Brasil é negra. 
                        <br />
                        <br />
                        Mas a voz dessa maioria é silenciada por um 
                        grupo dominante branco que só lê, escuta, segue 
                        e reproduz outras pessoas brancas. 
                        <br />
                        <br />
                        Não é por falta 
                        de referências e vozes negras que isso acontece. 
                        É pela falta de espaço na opinião pública.
                        <br />
                        <br />
                        O Projeto #VOZPRETA da Revista Raça nasceu para repensar o 
                        uso do Twitter através de uma nova ferramenta que vai amplificar 
                        as vozes da comunidade negra. 
                        <br />
                        <br />
                        Seja na área da educação, arte, ciência, entretenimento, 
                        política ou moda. 
                        <br />
                        <br />
                        Não importa. A partir de agora, 
                        onde houver um tweet, haverá uma #VOZPRETA.
                    </p>
                </div>
            </div>
            <div className="image-container"></div>
            <LinkRounded
                to='/'
                name='button-menu-tweetar mobile'
                buttonText='TWEET'
                outlineColor='white'
                background='transparent'
                color='white'
                width='140px'
                fontsize='var(--fontSizeDefault)'
                type='menu'
            />
        </div>
    )
}

export default Sobre
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Home from './views/Home';
import Sobre from './views/Sobre';
import Indique from './views/Indique';
import Download from './views/Download';
import MobileMenu from './views/MobileMenu';

import './assets/fonts/GoshaSans-Bold.otf'
import './assets/fonts/GoshaSans-Regular.otf'
import './assets/fonts/FHAlpha-Light.otf'

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

var wsStart = 'wss://';
// var endpoint = wsStart + 'localhost:8080' + '/occupy';
var endpoint = wsStart + 'ws.vozpreta.com.br' + '/occupy';
var socket = new WebSocket(endpoint);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home socket={socket} />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/indiqueumpreto" element={<Indique socket={socket} />} />
          {<Route path="/download-plugin" element={<Download />} />}
          <Route path="/menu" element={<MobileMenu />} />
        </Route>

        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="/sobre" element={<Sobre />} /> */}
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

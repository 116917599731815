import './LinkRounded.css';
import React from 'react'
import { tweetar } from '../Methods/methods'
import {
    Link,
    useMatch,
    useResolvedPath,
} from "react-router-dom";

const LinkRounded = (props) => {
    let resolved = useResolvedPath(props.to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <div>
            <Link className={'LinkRounded ' + props.name} to={props.to}>
                <div
                    style={{
                        // textDecoration: match ? "underline" : "none",
                        display: match ? "none" : "block",
                        border: '1px solid ' + props.outlineColor,
                        backgroundColor: props.background,
                        color: props.color,
                        width: props.width,
                        maxHeight: '50px',
                        textAlign: 'center',
                        padding: 'var(--defaultpadding) 0px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        fontSize: props.fontsize
                    }}
                >
                    {props.buttonText}
                </div>
            </Link>
        </div>
    )
}
export default LinkRounded;
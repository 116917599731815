import './Download.css'
import RoundedButton from '../components/RoundedButton/RoundedButton'

const Download = () => {

    return <div className='Download'>
        
        <img className='logo-alternative-indique desktop' src={require('../assets/images/logo-voz-preta.png')} />
        
            <div className='baixe-texto'>
                <img className='titulo' src={require('../assets/images/BAIXE-titulo.png')} />
                <a href='https://chrome.google.com/webstore/detail/voz-preta/kjaiefmkfpanmibjccdfogjdhkndoeda' target="_blank">
                <RoundedButton
                    name='button-baixar-plugin'
                    buttonText='BAIXAR'
                    outlineColor='white'
                    background='white'
                    color='black'
                    width='140px'
                    fontsize='calc(var(--fontSizeDefault) * 1.5)'
                    type='menu'
                    text=''
                    concatTweet='' />  
                </a>              
            </div>   
    </div>
}

export default Download
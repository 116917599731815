import LinkRounded from '../LinkRounded/LinkRounded'
import './MenuDesktop.css'

const MenuDesktop = () => {

    var indique = <p>INDIQUE <br /> UM PRETO</p>;
    var baixe = <p>BAIXE <br /> O PLUGIN</p>;

    return <div className='menuDesktop desktop-flex'>
        <div className='mainButtons'>

            <LinkRounded
                to='/sobre'
                name='button-menu-sobre'
                buttonText='SOBRE'
                outlineColor='white'
                background='transparent'
                color='white'
                width='140px'
                fontsize='var(--fontSizeDefault)'
                type='menu'
                text=''
                concatTweet=''
            />

            <LinkRounded
                to='/indiqueumpreto'
                name='button-menu-indique'
                buttonText={indique}
                outlineColor='white'
                background='transparent'
                color='white'
                width='140px'
                fontsize='var(--fontSizeDefault)'
                type='menu'
            />

            <LinkRounded
                to='/'
                name='button-menu-tweetar'
                buttonText='TWEET'
                outlineColor='white'
                background='transparent'
                color='white'
                width='140px'
                fontsize='var(--fontSizeDefault)'
                type='menu'
            />
        </div>

        <div className='footerButton'>
            <LinkRounded
                to='/download-plugin'
                name='button-menu-download'
                buttonText={baixe}
                outlineColor='white'
                background='transparent'
                color='white'
                width='140px'
                fontsize='var(--fontSizeDefault)'
                type='menu'
            />
        </div>
    </div>
}

export default MenuDesktop
